import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig.js";
import Cookies from "js-cookie";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const PageLayout = () => {
    // const [isAllowedObject, setAllowed] = useState({});
    const { instance, accounts } = useMsal();
    const [apiResponse, setApiResponse] = useState();
    const URL = window.location.pathname;
    const urlEndPoint = window.location.origin;
    const parametersString = URL.slice(1);
    console.log(parametersString);
    let parameters = parametersString.split("/");
    let receivedID_ = parameters[0];
    let customerID_ = parameters[1];
    let esginAction = parameters[2];
    if (receivedID_) {
        Cookies.set("UUID_Cookie", receivedID_);
    }
    if (customerID_) {
        Cookies.set("CustomerID", customerID_);
    }
    if (esginAction) {
        Cookies.set("esginAction", esginAction);
    }
    let UUID = Cookies.get("UUID_Cookie");
    let customerID = Cookies.get("CustomerID");
    let reviewAction = Cookies.get("esginAction");
    console.log(UUID + " " + customerID + " " + reviewAction);
    async function callApi(URL) {
        const response = await fetch(URL.substring(4), { mode: "no-cors" });
        const content = await response.json();
        setApiResponse(content);
    }
    const fetchData = async (customerID, UUID, reviewAction) => {
        try {
            const IdPConfigResponse = await fetch(urlEndPoint +
                "/api/getcustomer/" + customerID
            );
            const IdPConfigData = await IdPConfigResponse.json();
            const validationResponse = await fetch(urlEndPoint +
                "/api/checkIfAllowed/" + UUID);
            const validationData = await validationResponse.json();
            // setAllowed(JSON.parse(JSON.stringify(validationData)));
            useWork(validationData, IdPConfigData, UUID, reviewAction);
        } catch (error) {
            console.error(error);
        }

    };

    const useWork = async (isAllowedObject, configData, UUID, reviewAction) => {
        await delay(2000);
        try {
            console.log(isAllowedObject.allowed);
            if (isAllowedObject.allowed) {
                let clientArray = configData.defaultIdp;
                if (clientArray && clientArray.length) {
                    instance.controller.config.auth.clientId = clientArray[0].clientID;
                    instance.controller.config.auth.authority = "https://login.microsoftonline.com/" + clientArray[0].tenantID;
                }
                instance.loginRedirect(loginRequest);
            } else {
                window.location =

                    isAllowedObject.status;
            }
        } catch (error) {
            console.error('Error in useWork :', error)
            console.log("I am waiting for the response");
        }
    };

    useEffect(() => {
        console.log("parametersString ", parametersString)
        if (parametersString.includes("api/")) {
            callApi(URL)
        }
        else {
            fetchData(customerID, UUID, reviewAction);
        }

    }, []);

    return (
        <>
            {accounts && accounts.length
                ? initiateWork(accounts[0].username, UUID, reviewAction, urlEndPoint)
                : parametersString.includes("api/") ? apiResponse : null}
        </>
    );
}

async function initiateWork(username, id, reviewAction, urlEndPoint) {
    console.log("Username -> " + username + ", ID -> " + id + ", Action -> " + reviewAction);
    const response = await fetch(urlEndPoint +
        "/api/update",
        {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                mailId: username,
                uuid: id,
                approvalStatus: reviewAction
            }),
        }
    );
    const content = await response.json();
    console.log(JSON.stringify(content));
    window.location.assign(`${urlEndPoint}/api/success`);
    // await fetch(urlEndPoint + "/api/success", { mode: "no-cors" });
    // window.location = "api/success";
    delay(2000);
    Cookies.set("UUID_Cookie", null);
    Cookies.set("CustomerID", null);
}